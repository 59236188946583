
import { defineComponent } from "vue";

import DashboardSidebar from "@/components/common/DashboardSidebarSupplier.vue";
import FooterCopyright from "@/components/common/FooterCopyright.vue";

export default defineComponent({
  components: {
    DashboardSidebar,
    FooterCopyright,
  },

  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
});
