import axios from "axios";
import qs from "qs";
import { Config, HttpContractorIstance } from "./service.types";

export default class HttpAxios implements HttpContractorIstance {
  request(URL: string, config: Config) {
    const _config: any = { ...config };
    _config.paramsSerializer = (params: any) =>
      qs.stringify(params, { arrayFormat: "repeat", indices: false });

    return axios
      .request({
        url: URL,
        ..._config,
      })
      .then((res: any) => {
        const data = res.data;   
        if(res?.headers['x-app-version'] && typeof data === 'object') data.xAppVersion = res.headers['x-app-version'];
        return data;
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }
}
