export enum ButtonFontSize {
  ExtraSmall = "xs",
  Small = "sm",
  Medium = "md",
  Large = "lg",
}
export enum ButtonSize {
  XS = "xs",
  Small = "sm",
  Medium = "md",
  Bigger = "big",
  Large = "lg",
}

export enum ButtonType {
  Primary = "primary",
  Secondary = "secondary",
  Outline = "outline",
  Blue = "blue",
  Green = "green",
  Gray = "gray",
  White = "white",
  Confirm = "confirm",
  None = "none",
}
