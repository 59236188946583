import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/tailwind.css';
import './assets/scss/index.scss';
import Toast from 'vue-toastification';
import VueSocialauth from './plugins/vue-social-auth';
import 'floating-vue/dist/style.css';
import FloatingVue from 'floating-vue';
import VueSanitizeDirective from './plugins/vue-sanitize-directive';
import * as Sentry from '@sentry/vue';

const app = createApp(App);

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [process.env.VUE_APP_API_URL],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

app.use(store);
app.use(router);
app.use(FloatingVue);
app.use(VueSocialauth);
app.use(VueSanitizeDirective);
app.use(Toast, { icon: false, containerClassName: 'app-toast' });
app.mount('#app');
