import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-368f0906"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex h-full overflow-y-auto" }
const _hoisted_2 = { class: "w-full flex flex-col" }
const _hoisted_3 = { class: "main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dashboard_sidebar = _resolveComponent("dashboard-sidebar")!
  const _component_FooterCopyright = _resolveComponent("FooterCopyright")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_dashboard_sidebar),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true),
        _createVNode(_component_FooterCopyright)
      ])
    ])
  ]))
}