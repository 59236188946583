import { Layout } from '@/enums/layout';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import store from '@/store';
import http from '@/services/http';

import dayjs from 'dayjs';
import useShowError from '@/core/hooks/useShowError';

import { UserType } from '@/core/user-type';
import { HOME_PAGE_BY_ROLE, ROUTER_NAMES } from './router.enum';

async function onTierFinished(from: any, next: any) {
  if (from.path === '/') {
    await next({ name: ROUTER_NAMES.DASHBOARD });
  }
  store.commit('showTierModal');
  next(false);
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: ROUTER_NAMES.HOME,
    meta: {
      initial: true,
    },
    redirect: '',
  },
  {
    path: '/dashboard',
    name: ROUTER_NAMES.DASHBOARD,
    meta: {
      authRequired: true,
      layout: Layout.Dashboard,
    },
    component: () => import('@/modules/client/views/Dashboard.vue'),
  },
  {
    path: '/assessments',
    name: ROUTER_NAMES.ASSESSMENTS,
    meta: {
      authRequired: true,
      layout: Layout.Dashboard,
    },
    component: () => import('@/modules/client/views/Assessments.vue'),
  },
  {
    path: '/suppliers',
    name: ROUTER_NAMES.SUPPLIERS,
    meta: {
      authRequired: true,
      layout: Layout.Dashboard,
    },
    component: () => import('@/modules/client/views/Suppliers.vue'),
  },
  // {
  //   path: "/controls",
  //   name: ROUTER_NAMES.CONTROLS,
  //   meta: {
  //     authRequired: true,
  //     layout: Layout.Dashboard,
  //   },
  //   component: () => import("@/modules/client/views/Controls.vue"),
  // },
  {
    path: '/questionnaires',
    name: ROUTER_NAMES.QUESTIONNAIRES,
    meta: {
      authRequired: true,
      layout: Layout.Dashboard,
    },
    component: () => import('@/modules/client/views/Questionnaires.vue'),
  },
  {
    path: '/questionnaire/:id',
    name: ROUTER_NAMES.QUESTIONNAIRE,
    meta: {
      authRequired: true,
      layout: Layout.Dashboard,
    },
    component: () => import('@/modules/client/views/Questionnaire.vue'),
  },
  {
    path: '/supplier/:id',
    name: ROUTER_NAMES.SUPPLIER,
    meta: {
      authRequired: true,
      layout: Layout.Dashboard,
    },
    redirect: { name: ROUTER_NAMES.SUPPLIER_NESTED_ASSESMENT },
    component: () => import('@/modules/client/views/Supplier.vue'),
    children: [
      // Assesment tab
      {
        path: '',
        name: ROUTER_NAMES.SUPPLIER_NESTED_ASSESMENT,
        component: () =>
          import('@/modules/client/views/supplier/SupplierAssesment.vue'),
      },
      // Risk exposure tab
      {
        path: 'risk-exposure',
        name: ROUTER_NAMES.SUPPLIER_NESTED_RISK_EXPOSURE,
        component: () =>
          import('@/modules/client/views/supplier/SupplierRiskExposure.vue'),
      },
      // Functions tab
      {
        path: 'functions',
        name: ROUTER_NAMES.SUPPLIER_NESTED_FUNCTIONS,
        component: () =>
          import('@/modules/client/views/supplier/SupplierFunctions.vue'),
      },
      // Information tab
      {
        path: 'information',
        name: ROUTER_NAMES.SUPPLIER_NESTED_INFORMATION,
        component: () =>
          import('@/modules/client/views/supplier/SupplierInformation.vue'),
      },
      // Contacts tab
      {
        path: 'contacts',
        name: ROUTER_NAMES.SUPPLIER_NESTED_CONTACTS,
        component: () =>
          import('@/modules/client/views/supplier/SupplierContacts.vue'),
      },
      // logs tab
      {
        path: 'logs',
        name: ROUTER_NAMES.SUPPLIER_NESTED_LOGS,
        component: () =>
          import('@/modules/client/views/supplier/SupplierLogs.vue'),
      },
    ],
  },
  {
    path: '/create-supplier',
    name: ROUTER_NAMES.CREATE_SUPPLIER,
    meta: {
      authRequired: true,
      layout: Layout.Dashboard,
    },
    beforeEnter: async (to, from, next) => {
      await store.dispatch('fetchSubscription');
      const subscription = store.state.subscription.subscription;
      const now = dayjs();
      const validUntil = dayjs(subscription?.plan.current_period_end);
      if (validUntil.diff(now) <= 0) {
        await onTierFinished(from, next);
      } else if (
        subscription.suppliers_limit &&
        subscription.added_suppliers >= subscription.suppliers_limit
      ) {
        if (from.path === '/') {
          await next({ name: ROUTER_NAMES.DASHBOARD });
        }
        store.commit('showSupplierLimitModal');
        next(false);
      } else {
        next();
      }
    },
    component: () => import('@/modules/client/views/CreateSupplier.vue'),
  },
  {
    path: '/create-assessment',
    name: ROUTER_NAMES.CREATE_ASSESSMENT,
    meta: {
      authRequired: true,
      layout: Layout.Dashboard,
    },
    beforeEnter: async (to, from, next) => {
      await store.dispatch('fetchSubscription');
      next();
    },
    component: () => import('@/modules/client/views/CreateAssessment.vue'),
  },
  {
    path: '/assessments/:id',
    name: ROUTER_NAMES.ASSESSMENT,
    meta: {
      authRequired: true,
      layout: Layout.Dashboard,
    },
    component: () => import('@/modules/client/views/Assessment.vue'),
  },
  {
    path: '/profile',
    name: ROUTER_NAMES.PROFILE,
    meta: {
      authRequired: true,
      layout: Layout.Dashboard,
    },
    component: () => import('@/modules/profile/views/Profile.vue'),
    redirect: { name: ROUTER_NAMES.CLIENT_PROFILE_NESTED_USERS },
    children: [
      // users tabs
      {
        path: 'users',
        name: ROUTER_NAMES.CLIENT_PROFILE_NESTED_USERS,
        component: () =>
          import('@/modules/client/views/ClientUsersRouteTab.vue'),
      },
      {
        path: 'billing_history',
        name: ROUTER_NAMES.CLIENT_PROFILE_BILLING_HISTORY,
        component: () =>
          import('@/modules/client/views/ClientBillingHistoryTab.vue'),
      },
      {
        path: 'change_password',
        name: ROUTER_NAMES.CLIENT_PROFILE_CHANGE_PASS,
        component: () =>
          import('@/modules/client/views/ClientChangePassTab.vue'),
      },
      {
        path: 'zapier',
        name: ROUTER_NAMES.CLIENT_ZAPIER,
        component: () => import('@/modules/profile/views/ProfileZapierTab.vue'),
      },
    ],
  },
  {
    path: '/evaluator/profile',
    name: ROUTER_NAMES.PROFILE_EVALUATOR,
    meta: {
      authRequired: true,
      layout: Layout.EvaluatorDashboard,
    },
    component: () => import('@/modules/profile/views/Profile.vue'),
    redirect: { name: ROUTER_NAMES.EVALUATOR_ZAPIER },
    children: [
      // evaluator tabs
      {
        path: 'zapier',
        name: ROUTER_NAMES.EVALUATOR_ZAPIER,
        component: () => import('@/modules/profile/views/ProfileZapierTab.vue'),
      },
    ],
  },
  {
    path: '/privacy',
    name: ROUTER_NAMES.PRIVACY,
    component: () => import('@/modules/auth/views/Privacy.vue'),
    meta: {
      layout: Layout.Auth,
    },
  },
  {
    path: '/sign-in',
    name: ROUTER_NAMES.SIGN_IN,
    component: () => import('@/modules/auth/views/SignIn.vue'),
    meta: {
      layout: Layout.Auth,
    },
  },
  {
    path: '/sign-up',
    name: ROUTER_NAMES.SIGN_UP,
    component: () => import('@/modules/auth/views/SignUp.vue'),
    meta: {
      layout: Layout.SignUp,
    },
  },
  {
    path: '/forgot-password',
    name: ROUTER_NAMES.FORGOT_PASSWORD,
    component: () => import('@/modules/auth/views/ForgotPassword.vue'),
    meta: {
      layout: Layout.Auth,
    },
  },
  {
    path: '/reset_password',
    name: ROUTER_NAMES.RESET_PASSWORD,
    component: () => import('@/modules/auth/views/CreatePassword.vue'),
    meta: {
      layout: Layout.Auth,
    },
  },
  {
    path: '/complete_registration',
    name: ROUTER_NAMES.CREATE_PASSWORD,
    component: () => import('@/modules/auth/views/CreatePassword.vue'),
    meta: {
      layout: Layout.Auth,
    },
  },
  {
    path: '/social-auth/:provider',
    name: ROUTER_NAMES.SOCIAL_AUTH,
    component: () => import('@/modules/auth/views/SocialAuth.vue'),
    meta: {
      layout: Layout.Auth,
    },
  },
  {
    path: '/try-again',
    name: ROUTER_NAMES.TRY_AGAIN,
    component: () => import('@/modules/auth/views/TryAgain.vue'),
    meta: {
      layout: Layout.Auth,
    },
  },
  {
    path: '/supplier/assessments',
    name: ROUTER_NAMES.SUPPLIER_ASSESSMENTS,
    component: () => import('@/modules/supplier/views/Assessments.vue'),
    meta: {
      authRequired: true,
      layout: Layout.SupplierDashboard,
    },
  },
  {
    path: '/supplier/assessments/:id',
    name: ROUTER_NAMES.SUPPLIER_ASSESSMENT,
    component: () => import('@/modules/supplier/views/Assessment.vue'),
    meta: {
      authRequired: true,
      layout: Layout.SupplierDashboard,
    },
  },
  {
    path: '/evaluator/assessments',
    name: ROUTER_NAMES.EVALUATOR_ASSESSMENTS,
    component: () => import('@/modules/evaluator/views/Assessments.vue'),
    meta: {
      authRequired: true,
      layout: Layout.EvaluatorDashboard,
    },
  },
  {
    path: '/evaluator/clients',
    name: ROUTER_NAMES.EVALUATOR_CLIENTS,
    component: () =>
      import(
        /* webpackChunkName: "about" */ '@/modules/evaluator/views/Clients.vue'
      ),
    meta: {
      authRequired: true,
      layout: Layout.EvaluatorDashboard,
    },
  },
  {
    path: '/evaluator/clients/create',
    name: ROUTER_NAMES.EVALUATOR_CLIENTS_CREATE,
    component: () =>
      import(
        /* webpackChunkName: "about" */ '@/modules/evaluator/views/ClientsCRUD.vue'
      ),
    meta: {
      authRequired: true,
      layout: Layout.EvaluatorDashboard,
    },
  },
  {
    path: '/evaluator/clients/:id',
    name: ROUTER_NAMES.EVALUATOR_CLIENTS_EDIT,
    component: () =>
      import(
        /* webpackChunkName: "about" */ '@/modules/evaluator/views/Client.vue'
      ),
    meta: {
      authRequired: true,
      layout: Layout.EvaluatorDashboard,
    },
    redirect: { name: ROUTER_NAMES.EVALUATOR_CLIENTS_SUPPLIERS },
    children: [
      {
        path: '/evaluator/clients/:id/suppliers',
        name: ROUTER_NAMES.EVALUATOR_CLIENTS_SUPPLIERS,
        component: () =>
          import('@/modules/evaluator/views/ClientsSuppliersTab.vue'),
      },
      {
        path: '/evaluator/clients/:id/questionnaires',
        name: ROUTER_NAMES.EVALUATOR_CLIENTS_QUESTIONNAIRES,
        component: () =>
          import('@/modules/evaluator/views/ClientsQuestionnairesTab.vue'),
      },
      {
        path: '/evaluator/clients/:id/users',
        name: ROUTER_NAMES.EVALUATOR_CLIENTS_USERS,
        component: () =>
          import('@/modules/evaluator/views/ClientsUsersTab.vue'),
      },
      {
        path: '/evaluator/clients/:id/subscriptions',
        name: ROUTER_NAMES.EVALUATOR_CLIENTS_SUBSCRIPTIONS,
        component: () =>
          import('@/modules/evaluator/views/ClientsSubscriptionsTab.vue'),
      },
      {
        path: '/evaluator/clients/:id/pro-evaluations',
        name: ROUTER_NAMES.EVALUATOR_CLIENTS_PRO_EVALUATIONS,
        component: () =>
          import('@/modules/evaluator/views/ClientsEvaluationsTab.vue'),
      },
      {
        path: '/evaluator/clients/:id/billing-history',
        name: ROUTER_NAMES.EVALUATOR_CLIENTS_BILLING_HISTORY,
        component: () =>
          import('@/modules/evaluator/views/ClientsBillingHistoryTab.vue'),
      },
    ],
  },
  {
    path: '/evaluator/assessments/:id',
    name: ROUTER_NAMES.EVALUATOR_ASSESSMENT,
    component: () => import('@/modules/evaluator/views/Assessment.vue'),
    meta: {
      authRequired: true,
      layout: Layout.EvaluatorDashboard,
    },
  },
  {
    path: '/evaluator/questionnaires',
    name: ROUTER_NAMES.EVALUATOR_QUESTIONNAIRES,
    component: () => import('@/modules/evaluator/views/Questionnaires.vue'),
    meta: {
      authRequired: true,
      layout: Layout.EvaluatorDashboard,
    },
  },
  {
    path: '/evaluator/questionnaire/:id',
    name: ROUTER_NAMES.EVALUATOR_QUESTIONNAIRE,
    component: () => import('@/modules/evaluator/views/Questionnaire.vue'),
    meta: {
      authRequired: true,
      layout: Layout.EvaluatorDashboard,
    },
  },
  {
    path: '/evaluator/dashboard',
    name: ROUTER_NAMES.EVALUATOR_DASHBOARD,
    component: () => import('@/modules/evaluator/views/EvaluatorDashboard.vue'),
    meta: {
      authRequired: true,
      layout: Layout.EvaluatorDashboard,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: ROUTER_NAMES.NOT_FOUND,
    component: () => import('@/modules/NotFound.vue'),
    meta: {
      authRequired: false,
      layout: Layout.LogoLayout,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.query && to.query.access && typeof to.query.access === 'string') {
    http.setSupplierAccessToken(to.query.access.toString());
    store.dispatch('supplierAssessmentOpened');
    const currentQuery = { ...to.query };
    delete currentQuery.access;
    return next({ path: to.path, query: { ...currentQuery } });
  }
  if (to.matched.some((record) => record.meta.initial)) {
    const user: UserType = await store.dispatch('fetchUser');
    if (user) {
      next({ name: HOME_PAGE_BY_ROLE[user.role] });
    } else {
      next({ name: ROUTER_NAMES.SIGN_IN, query: { from: to.path } });
    }
  } else if (to.matched.some((record) => record.meta.authRequired)) {
    const user: UserType = await store.dispatch('fetchUser');

    if (user) {
      next();
    } else {
      next({ name: ROUTER_NAMES.SIGN_IN, query: { from: to.path } });
    }
  } else {
    next();
  }
});

export default router;
