import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74e517e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled", "type"]
const _hoisted_2 = {
  key: 1,
  class: "loading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiCircleLoader = _resolveComponent("UiCircleLoader")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(_ctx.buttonClasses),
    style: _normalizeStyle({ width: `${_ctx.width}px` }),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click'))),
    disabled: _ctx.disabled,
    type: _ctx.type
  }, [
    (_ctx.$slots['before_icon'])
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(["ui-button__icon ui-button__icon--before mr-3", { 'ui-button__stroke': _ctx.stroke, 'ui-button__fill': _ctx.fill }])
        }, [
          _renderSlot(_ctx.$slots, "before_icon", {}, undefined, true)
        ], 2))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _createVNode(_component_UiCircleLoader, {
            size: "24px",
            stroke: "2px"
          })
        ]))
      : _createCommentVNode("", true)
  ], 14, _hoisted_1))
}